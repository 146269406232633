import { IPriceLockupFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'

// Component
import PriceLockup from './PriceLockup'

const ContentfulPriceLockup = withContentful<IPriceLockupFields>(PriceLockup, {
  spacer: mapSpacer,
  bottomTextAlignment: (props) => props.fields?.bottomTextAlignment?.toLowerCase(),
})

export default ContentfulPriceLockup
